/**
 * URLs for live and delayed scanners.
 */
const liveURL = process.env.REACT_APP_LIVE_URL ?? 'https://as-live.f2-tech.com/scanners';
const delayedURL = process.env.REACT_APP_DELAYED_URL ?? 'https://as-dlyd.f2-tech.com/scanners';
const allowedRoles = ['admin', 'customer', 'read-only admin'];

const setupSubScannersForAdmins = (arr, scannerData, isLive, token) =>
  arr
    .reduce((acc, scanner) => {
      const groupName = scanner?.title;
      if (!groupName || !scannerData?.slug) return acc;
      const delayedName = `${scanner?.title} Delayed`;
      const delayedRedirect = `${delayedURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=true&token=${token}`;
      const liveRedirect = `${liveURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=false&token=${token}`;
      const isOver6 = scanner?.title?.includes('Over6');
      const over6Title = isOver6 ? scanner?.title.replace('Over6', 'PRS') : scanner?.title;

      if (isOver6) {
        return [
          ...acc,
          {
            ...scanner,
            visibleName: over6Title,
            locked: false,
            live: true,
            redirect: !isLive ? liveRedirect : null,
          },
        ];
      }
      const delayedTab = {
        ...scanner,
        visibleName: delayedName,
        locked: false,
        live: false,
        redirect: isLive ? delayedRedirect : null,
      };
      const liveTab = {
        ...scanner,
        visibleName: scanner?.title,
        locked: false,
        live: true,
        redirect: !isLive ? liveRedirect : null,
      };
      return [...acc, delayedTab, liveTab];
    }, [])
    .sort((a, b) => a.title.localeCompare(b.title));

const setupSubScannersForMembers = (arr, scannerData, isLive, token, liveAccess) =>
  arr
    .reduce((acc, scanner) => {
      const groupName = scanner?.title;
      if (!groupName || !scannerData?.slug) return acc;
      const subScanner =
        scannerData?.subscanners.find((s) => {
          if (s?.name?.includes('PRS')) {
            const scTitle = scanner?.title.replace('Over6', 'PRS');
            return s.name === scTitle;
          }
          return s.name === groupName;
        }) ?? false;

      const isOver6 = scanner?.title?.includes('Over6');
      const delayedName = `${scanner?.title} Delayed`;
      const visibleName = isOver6 ? scanner?.title.replace('Over6', 'PRS') : scanner?.title;
      // If user does not have access to the subscanner
      if (!subScanner) {
        return [
          ...acc,
          {
            ...scanner,
            visibleName: delayedName,
            locked: true,
          },
          {
            ...scanner,
            visibleName,
            locked: true,
          },
        ];
      }

      const delayedRedirect = `${delayedURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=true&token=${token}`;
      const liveRedirect = `${liveURL}/${scannerData?.slug}?group=${encodeURIComponent(
        scanner.title,
      )}&delayed=false&token=${token}`;
      const userHasAccess = subScanner?.live ? liveAccess && !!subScanner : !!subScanner;

      if (isOver6) {
        return [
          ...acc,
          {
            ...scanner,
            visibleName,
            locked: !userHasAccess,
            live: true,
            redirect: !isLive && userHasAccess ? liveRedirect : null,
          },
        ];
      }

      const liveTab = {
        ...scanner,
        visibleName,
        locked: !(userHasAccess && subScanner?.live),
        live: true,
        redirect: !isLive && userHasAccess ? liveRedirect : null,
      };

      // If User has live access only show live tab
      if (userHasAccess && subScanner?.live) {
        return [...acc, liveTab];
      }

      const delayedTab = {
        ...scanner,
        visibleName: delayedName,
        locked: !userHasAccess,
        live: false,
        redirect: isLive ? delayedRedirect : null,
      };

      return [...acc, delayedTab, liveTab];
    }, [])
    .sort((a, b) => a.title.replace(' Delayed', '').localeCompare(b.title.replace(' Delayed', '')))
    .sort((a, b) => a?.locked - b?.locked);

const setupSubScannersForNoAccess = (arr, scannerData) => {
  const formattedForSidebar = arr
    .reduce((acc, scanner) => {
      const groupName = scanner?.title;
      if (!groupName || !scannerData?.slug) return acc;
      const delayedName = `${scanner?.title} Delayed`;
      const isOver6 = scanner?.title?.includes('Over6');
      const over6Title = isOver6 ? scanner?.title.replace('Over6', 'PRS') : scanner?.title;

      if (isOver6) {
        return [
          ...acc,
          {
            ...scanner,
            visibleName: over6Title,
            locked: true,
            live: true,
            redirect: null,
          },
        ];
      }
      const delayedTab = {
        ...scanner,
        visibleName: delayedName,
        locked: true,
        live: false,
        redirect: null,
      };
      const liveTab = {
        ...scanner,
        visibleName: scanner?.title,
        locked: true,
        live: true,
        redirect: null,
      };
      return [...acc, delayedTab, liveTab];
    }, [])
    .sort((a, b) => a.title.replace(' Delayed', '').localeCompare(b.title.replace(' Delayed', '')));
  formattedForSidebar.push({
    group: 'noscanners',
    type: 'no-tabs-available',
    visibleName: 'No Scanners Available',
    title: 'No Scanners Available',
    locked: false,
  });
  return formattedForSidebar;
};

const setupSubScanners = (scannerData, groups, isLive, userData) => {
  const filtered = groups.filter((g) => g.type !== 'report' && !g?.hideTab);
  const {liveAccess} = scannerData ?? {};
  const url = new URL(window.location.href);
  let token = url.searchParams.get('token');
  if (token) {
    localStorage.setItem('scanner-sso', token);
  } else {
    token = localStorage.getItem('scanner-sso');
  }

  if (!scannerData?.useSubScanners) {
    // If scanner is not using subscanners
    return filtered.map((scanner) => ({
      ...scanner,
      locked: false,
      redirect: null,
    }));
  }

  // If user has admin, customer, or read-only admin role
  if (allowedRoles.includes(userData?.role)) {
    return setupSubScannersForAdmins(filtered, scannerData, isLive, token);
  }

  // If user has subscanner array
  if (scannerData?.subscanners?.length) {
    const scanners = setupSubScannersForMembers(filtered, scannerData, isLive, token, liveAccess);
    scanners.push({
      group: 'noscanners',
      type: 'no-tabs-available',
      visibleName: 'No Scanners Available',
      title: 'No Scanners Available',
      locked: false,
    });

    return scanners;
  }
  // If user does not have a subscanner array
  return setupSubScannersForNoAccess(filtered, scannerData);
};
export default setupSubScanners;
